<template>
  <div>
    <v-row>
      <v-col md="12">
        <v-card elevation="10">
          <v-toolbar
            dense
            color="#003d6a"
            elevation="0"
            style="z-index: 9"
            class="text-white"
          >
            <v-toolbar-title>Mutabakatlar</v-toolbar-title>
            <v-spacer></v-spacer>

            <div class="d-flex justify-content-between align-items-center">
              <div class="flex-grow-1" style="min-width: 800px">
                <CForm @submit.prevent="search">
                  <CContainer>
                    <CRow class="align-items-center">
                      <CCol col="4" class="px-0 pr-2">
                        <v-menu
                          v-model="datePickerMenu"
                          :close-on-content-click="false"
                        >
                          <template v-slot:activator="{ on, attrs }">
                            <v-text-field
                              :value="
                                $moment(searchForm.date[0]).format(
                                  'DD.MM.YYYY'
                                ) +
                                ' - ' +
                                $moment(searchForm.date[1]).format('DD.MM.YYYY')
                              "
                              clearable
                              readonly
                              placeholder="Tarih Aralığı"
                              dense
                              hide-details
                              outlined
                              v-bind="attrs"
                              class="bg-white text-black"
                              v-on="on"
                              @click:clear="searchForm.date = []"
                            ></v-text-field>
                          </template>
                          <v-date-picker
                            range
                            v-model="searchForm.date"
                            @change="(datePickerMenu = false), search()"
                          ></v-date-picker>
                        </v-menu>
                      </CCol>
                      <CCol col="3" class="px-0 pr-2">
                        <input
                          type="text"
                          v-model="searchFilterInput"
                          class="form-control"
                          style="max-width: 250px"
                          autocomplete="new"
                          :placeholder="$t('search_t')"
                          v-on:keyup="onFilterTextBoxChanged()"
                        />
                      </CCol>
                      <CCol col="2" class="px-0 pr-2">
                        <CInput
                          v-model="searchForm.rezervationCode"
                          class="mb-0"
                          type="text"
                          placeholder="RZR No"
                        />
                        <button
                          style="
                            position: absolute;
                            right: 10px;
                            top: 17px;
                            background: #003d6a;
                            border-radius: 10px;
                            padding: 2px 12px;
                          "
                          @click="search(searchForm.rezervationCode)"
                        >
                          Ara
                        </button>
                      </CCol>
                      <CCol col="3" class="px-0 pr-2">
                        <v-select
                          v-model="searchForm.vendor"
                          :options="items.vendors"
                          :reduce="(item) => item.brandSlug"
                          placeholder="Firmalar"
                          label="brand"
                          id="vendor"
                        >
                          <template #selected-option="{ brand }">
                            <div
                              style="
                                display: inline-block;
                                width: 100%;
                                height: 30px;
                                overflow: hidden;
                                font-size: 12px;
                              "
                            >
                              <p>{{ brand.slice(0, 8) }}...</p>
                            </div>
                          </template>
                        </v-select>
                      </CCol>

                      <!-- <CCol col="2" class="px-0 pr-2">
                        <CSelect
                          class="m-0"
                          placeholder="Ay"
                          :value.sync="mount"
                          :options="items.mounts"
                        />
                      </CCol>

                      <CCol col="2" class="px-0">
                        <CSelect
                          class="m-0"
                          :value.sync="year"
                          placeholder="Yıl"
                          :options="years"
                        />
                      </CCol> -->
                    </CRow>
                  </CContainer>
                </CForm>
              </div>
              <div class="d-flex"></div>
            </div>
          </v-toolbar>

   
            <ag-grid-vue
              style="
                width: 100%;
                height: calc(100vh - 152px);
                padding-bottom: 3rem;
              "
              :class="getClasses()"
              :columnDefs="columnDefs"
              :defaultColDef="defaultColDef"
              :rowData="rowData"
              :sideBar="sideBar"
              :rowSelection="rowSelection"
              :enableRangeSelection="true"
              :enableBrowserTooltips="true"
              :statusBar="statusBar"
              @column-moved="onColumnMoved"
              @column-pinned="onColumnMoved"
              @column-visible="onColumnMoved"
              @column-resized="onColumnMoved"
              @column-changed="onColumnMoved"
              @column-row-group-changed="onColumnMoved"
              :rowClassRules="rowClassRules"
              @grid-ready="onGridReady"
              @rowDoubleClicked="onRowDoubleClicked"
              :getContextMenuItems="getGridContextMenuItems"
              @selection-changed="onSelectionChanged"
            ></ag-grid-vue>
       
        
          <div class="custom-status-bar">
            <span
              ><strong>Müş. Top. Kiralama:</strong>
              {{ statusBarTotalPrice }}</span
            >
            <span
              ><strong>Toplam Komisyon:</strong>
              {{ statusBarTotalCommisionPrice }}</span
            >
            <span
              ><strong>İnd. Ted. Top. Tutarı:</strong>
              {{ discountVendorAllTotalPrice }}</span
            >
            <span
              ><strong>Ted. Net. Ödenecek:</strong>
              {{ vendorTotalAmount }}</span
            >

            <span><strong>Toplam Gün :</strong> {{ statusBarTotalDay }}</span>
            <span><strong>Ortalama Gün :</strong> {{ averageDay }}</span>
          </div>
        </v-card></v-col
      >
    </v-row>
    <CModal
      v-if="modalUpdate"
      title="Mutabakat Düzenle"
      size="lg"
      :show.sync="modalUpdate"
    >
      <CCol col="3" class="px-0 pr-2">
        <CSelect
          label="Durum"
          class="m-0"
          :placeholder="modalUpdateForm.status"
          :value.sync="modalUpdateForm.status"
          :options="items.status"
        />
      </CCol>
      <template #footer>
        <CButton @click="update" color="success"> Güncelle </CButton>
      </template>
    </CModal>
    <CModal title="Ret Sebebi" :show.sync="reasonForReject.modalShow">
      <CContainer>
        <CTextarea
          label="Sebep"
          class="font-weight-bold"
          v-model="reasonForReject.data"
        />
      </CContainer>
      <template #footer>
        <CButton @click="reasonForReject.modalShow = false" color="Danger">
          İptal
        </CButton>
        <CButton @click="reasonForRejectAction()" color="success">
          Gönder
        </CButton>
      </template>
    </CModal>
  </div>
</template>
<script>
import Vue from "vue";
import axios from "axios";
import { AgGridVue } from "ag-grid-vue";
import VueSweetalert2 from "vue-sweetalert2";
import moment from "moment";
import vSelect from "vue-select";
var _ = require("lodash");
import AgentNote from "@/components/AgentNote";
import "vue-select/dist/vue-select.css";
import GogoLoading from "../../components/GogoLoading.vue";

import func from "../../func";
axios.defaults.withCredentials = true;
Vue.use(VueSweetalert2);

export default {
  name: "VendorRate",
  components: {
    AgGridVue,
    vSelect,
    AgentNote,
    GogoLoading,
  },
  data() {
    return {
      datePickerMenu: false,
      reasonForReject: {
        modalShow: false,
        data: "",
        row: {},
      },
      searchFilterInput: "",
      discountVendorAllTotalPrice: 0,
      statusBarTotalPrice: 0,
      statusBarTotalCommisionPrice: 0,
      statusBarTotalDay: 0,
      averageDay: 0,
      vendorTotalAmount: 0,
      loadingGrid: false,
      rowSelection: "multiple",
      errText: "",
      ENV_URL_CAMPAIGN: process.env.VUE_APP_CDN_URL_CAMPAIGN,
      editorConfig: {},
      defaultImage: "default_campaign.jpeg",
      gridShow: false,
      columnDefs: [
        {
          field: "status",
          width: 140,
          headerName: "Durum",
          checkboxSelection: true,
          headerCheckboxSelection: true,
          headerCheckboxSelectionFilteredOnly: true,
          cellStyle: (params) => {
            return (params.value === params?.data?.refund?.status) == true
              ? { backgroundColor: "#FFE5B4" }
              : {};
          },
        },
        {
          field: "reservationStatus",
          headerName: "Rez.Durumu",
          cellRenderer: (params) => {
            var result = "";
            var reservationStatus = params.value;
            if (reservationStatus == "approved") {
              result = this.$t("approved");
            } else if (reservationStatus == "pending") {
              result = this.$t("pending_t");
            } else if (reservationStatus == "active") {
              result = this.$t("active");
            } else if (reservationStatus == "completed") {
              result = this.$t("completed");
            } else if (reservationStatus == "cancelled") {
              result = this.$t("cancelled");
            } else if (reservationStatus == "billed") {
              result = this.$t("billed");
            } else if (reservationStatus == "notbilled") {
              result = this.$t("notbilled");
            } else if (reservationStatus == "canceledbill") {
              result = this.$t("canceledbill");
            } else if (reservationStatus == "earlyReturnRequest") {
              result = this.$t("earlyReturnRequest");
            } else if (
              reservationStatus == "earlyReturnFinancialConfirmation"
            ) {
              result = this.$t("earlyReturnRequestWaitConfirm");
            } else if (reservationStatus == "confirmedEarlyReturn") {
              result = this.$t("confirmEarlReturn");
            } else if (reservationStatus == "noShowCancelled") {
              result = this.$t("noShowCancelled");
            } else if (reservationStatus == "paymentError") {
              result = this.$t("paymentError");
            } else {
              result = this.$t("all");
            }

            return result;
          },
          width: 130,
          hide:
            typeof this.$router.history.current.params.reservationStatus !==
            "undefined"
              ? true
              : false,
        },
        {
          field: "dateConsensus",
          cellRenderer: (params) => {
            return moment(params.value).format("DD.MM.YYYY");
          },
          headerName: "İşlem Tarihi",
          type: "rightAligned",
          width: 130,
          hide: true,
        },
        {
          field: "reservationDateTime",
          cellRenderer: (params) => {
            return moment(params.value).format("DD.MM.YYYY");
          },
          headerName: "Rezervasyon Tarihi",
          type: "rightAligned",
          width: 130,
        },
        {
          field: "searchPrice.vendor.name",
          headerName: "Tedarikçi",
          width: 130,
          enableRowGroup: true,
        },
        {
          field: "vendorReservationCode",
          width: 150,
          headerName: "Tedarikçi Rez. Kodu",
        },
        {
          field: "reservationCode",
          width: 130,
          headerName: "Renticar Rez. Kodu",
        },
        {
          field: "customerFullName",
          headerName: "Müşteri",
          width: 180,
        },
        {
          field: "searchPrice.pricing.dailyPrice.TRY",
          cellStyle: (params) => {
            return params.value ===
              params?.data?.searchPrice?.pricing?.vendorDailyPrice?.TRY
              ? { fontWeight: "light" }
              : { fontWeight: "bold" };
          },
          cellRenderer: (params) => {
            return params.value + " TL";
          },
          type: "rightAligned",
          headerName: "Müşt. Günlük Tutarı",
          width: 150,
        },
        {
          field: "totalRentalPrice",
          type: "rightAligned",
          width: 150,
          cellStyle: (params) => {
            return params.value === params?.data?.totalPrice
              ? { fontWeight: "light" }
              : { fontWeight: "bold" };
          },
          cellRenderer: (params) => {
            return (
              new Intl.NumberFormat("tr-TR", {
                style: "currency",
                currency: "TRY",
                minimumFractionDigits: 2,
              })
                .format(params.value)
                .replace("₺", "") + " TL"
            );
          },
          headerName: "Müşt. Toplam Tutar",
          aggFunc: "sum",
        },
        {
          field: "searchPrice.pricing.vendorDailyPrice.TRY",
          cellStyle: (params) => {
            return params.value === params?.data?.discountedVendorDailyPrice
              ? { fontWeight: "light" }
              : { fontWeight: "bold" };
          },
          cellRenderer: (params) => {
            return (
              new Intl.NumberFormat("tr-TR", {
                style: "currency",
                currency: "TRY",
                minimumFractionDigits: 2,
              })
                .format(params.value)
                .replace("₺", "") + " TL"
            );
          },
          type: "rightAligned",
          //headerName: "Tedarikçi Günlük Tutarı",
          headerName: "API " + this.$t("daily_price"),
          width: 150,
        },

        {
          field: "discountedVendorDailyPrice",
          cellStyle: (params) => {
            return params.value ===
              params?.data?.searchPrice?.pricing?.vendorDailyPrice?.TRY
              ? { fontWeight: "light" }
              : { fontWeight: "bold" };
          },
          cellRenderer: (params) => {
            return (
              new Intl.NumberFormat("tr-TR", {
                style: "currency",
                currency: "TRY",
                minimumFractionDigits: 2,
              })
                .format(params.value)
                .replace("₺", "") + " TL"
            );
          },

          type: "rightAligned",
          headerName: "İnd. API. Günlük Tutarı",
          width: 150,
        },

        {
          field: "totalPrice",
          type: "rightAligned",
          width: 150,
          cellStyle: (params) => {
            return params.value === params?.data?.discountedVendorTotalPrice
              ? { fontWeight: "light" }
              : { fontWeight: "bold" };
          },
          cellRenderer: (params) => {
            return (
              new Intl.NumberFormat("tr-TR", {
                style: "currency",
                currency: "TRY",
                minimumFractionDigits: 2,
              })
                .format(params.value)
                .replace("₺", "") + " TL"
            );
          },
          //headerName: "Tedarikçi Toplam Tutar",
          headerName: this.$t("vendor_total_rental_price"),
          aggFunc: "sum",
        },

        {
          field: "discountedVendorTotalPrice",
          cellStyle: (params) => {
            return params.value === params?.data?.totalPrice
              ? { fontWeight: "light" }
              : { fontWeight: "bold" };
          },
          cellRenderer: (params) => {
            return (
              new Intl.NumberFormat("tr-TR", {
                style: "currency",
                currency: "TRY",
                minimumFractionDigits: 2,
              })
                .format(params.value)
                .replace("₺", "") + " TL"
            );
          },
          type: "rightAligned",
          headerName: "İnd. API. Toplam Tutarı",
          width: 120,
        },
        {
          field: "refundPaymentAmount",
          // cellStyle: { color: "red" },
          cellStyle: (params) => {
            return params?.data?.paymentType == "debit"
              ? { color: "red", fontWeight: "bold" }
              : { color: "blue", fontWeight: "bold" };
          },
          cellRenderer: (params) => {
            if (params.value == 0) {
              return "-";
            } else {
              return (
                new Intl.NumberFormat("tr-TR", {
                  style: "currency",
                  currency: "TRY",
                  minimumFractionDigits: 2,
                })
                  .format(params.value)
                  .replace("₺", "") + " TL"
              );
            }
          },
          type: "rightAligned",
          headerName: "İade Edilen Tutar",
          width: 80,
        },
        {
          field: "amountPaid",
          cellStyle: {
            color: "white",
            "background-color": "green",
            "font-weight": "bold",
          },
          cellRenderer: (params) => {
            return (
              new Intl.NumberFormat("tr-TR", {
                style: "currency",
                currency: "TRY",
                minimumFractionDigits: 2,
              })
                .format(params.value)
                .replace("₺", "") + " TL"
            );
          },
          type: "rightAligned",
          headerName: "Tedarikçiden Beklenen Fatura Tutarı",
          width: 180,
        },
        {
          field: "paymentType",
          valueGetter: (params) => {
            let type = params?.data?.paymentType
              ? this.$store.state.ceviri[params.data.paymentType]
              : "";
            return type;
          },
          headerName: this.$t("payment_type"),
          width: 130,
        },
        {
          field: "commissionRate",
          type: "rightAligned",
          width: 150,
          cellRenderer: (params) => {
            return params.value + " %";
          },
          headerName: "Komisyon Oranı",
          aggFunc: "avg",
        },
        {
          field: "commissionAmount",
          type: "rightAligned",
          width: 150,
          cellRenderer: (params) => {
            return (
              new Intl.NumberFormat("tr-TR", {
                style: "currency",
                currency: "TRY",
                minimumFractionDigits: 2,
              })
                .format(params.value)
                .replace("₺", "") + " TL"
            );
          },
          headerName: "Komisyon Tutarı",
          aggFunc: "sum",
        },

        {
          field: "campaignName",
          headerName: "Kampanya",
          cellRenderer: (params) => {
            const CampaignName = params.value == "" ? "-" : params.value;
            return CampaignName;
          },
          width: 150,
        },
        {
          field: "vendorReflection",
          type: "rightAligned",
          width: 150,

          cellRenderer: (params) => {
            const type =
              params.data.campaignDiscountType === "Tutar" ? " TL" : " %";
            const vendorReflection =
              params.value == 0 ? "-" : params.value + type;
            return vendorReflection;
          },
          headerName: "Tedarikçi Kampanya Katılım Oranı",
          aggFunc: "avg",
        },

        {
          field: "partnerName.title",
          headerName: "Partner Adı",
          cellRenderer: (params) => {
            const partnerName = params.value == null ? "-" : params.value;
            return partnerName;
          },
          width: 150,
        },
        {
          field: "rentalDays",
          type: "rightAligned",
          width: 150,
          cellRenderer: (params) => {
            return params.value + " Gün";
          },
          headerName: "Kiralama Süresi",
          aggFunc: "sum",
        },

        {
          field: "currencyPrices.currencyCode",
          type: "rightAligned",
          width: 130,
          valueGetter: (params) => {
            const currencyUnit = params.data.currencyPrices.currencyCode;
            if (currencyUnit === "") {
              return "TRY";
            } else {
              return currencyUnit;
            }
          },
          headerName: "Rezervasyon Para Birimi",
        },

        {
          field: "totalExtraPrice",
          cellRenderer: (params) => {
            const CampaignName = params.value == "0,00 TL" ? "-" : params.value;
            return CampaignName;
          },
          width: 130,
          type: "rightAligned",
          headerName: "Ekstra Toplam",
        },
        {
          field: "dropPrice",
          cellRenderer: (params) => {
            const CampaignName = params.value == "0,00 TL" ? "-" : params.value;
            return CampaignName;
          },
          width: 130,
          type: "rightAligned",
          headerName: "Farklı Yere",
        },
        {
          field: "reservationType",
          cellStyle: (params) => ({
            "font-weight": params.value === "fullCredit" ? "bold" : "normal",
          }),
          cellRenderer: (params) => {
            const resType =
              params.value === "fullCredit"
                ? "FullCredit"
                : params.value
                ? "Normal"
                : "-";
            return resType;
          },
          headerName: "Kiralama Tipi",
          width: 130,
        },
        {
          field: "campaignDiscountType",
          cellRenderer: (params) => {
            return params.value == "" ? "-" : params.value;
          },
          headerName: "İndirim Tipi",
          width: 130,
        },

        {
          field: "campaignDiscountValue",
          cellRenderer: (params) => {
            return params.value == "" ? "-" : params.value;
          },
          headerName: "İndirim Değeri",
          width: 130,
        },
        {
          field: "currencyPrices.totalDaily",
          cellRenderer: (params) => {
            return `${params.value} ${params.data.currencyPrices.currencyCode}`;
          },
          type: "rightAligned",
          headerName: "Müşt. Günlük Döviz Tutarı",
          width: 140,
          hide: true,
        },
        {
          field: "searchPrice.pricing.dailyPrice.EUR",
          cellStyle: (params) => {
            return params.value ===
              params?.data?.searchPrice?.pricing?.vendorDailyPrice?.EUR
              ? { fontWeight: "light" }
              : { fontWeight: "bold" };
          },
          cellRenderer: (params) => {
            let forexSelling =
              params.data.searchPrice.pricing.dailyPrice.EUR == ""
                ? ""
                : params.data.searchPrice.pricing.dailyPrice.EUR.toLocaleString(
                    "tr-TR",
                    {
                      style: "currency",
                      currency: "EUR",
                    }
                  );
            return forexSelling;
          },
          type: "rightAligned",
          headerName: "Müşt. Günl. EURO Tutarı",
          width: 140,
        },
        {
          field: "currencyPrices.total",
          cellRenderer: (params) => {
            return `${params.value} ${params.data.currencyPrices.currencyCode}`;
          },
          type: "rightAligned",
          headerName: "Müşteri Toplam Döviz Tutarı",
          width: 120,
          aggFunc: "sum",
          hide: true,
        },
        {
          field: "searchPrice.pricing.totalPrice.EUR",
          cellStyle: (params) => {
            return params.value ===
              params?.data?.searchPrice?.pricing?.vendorTotalPrice?.EUR
              ? { fontWeight: "light" }
              : { fontWeight: "bold" };
          },
          cellRenderer: (params) => {
            let forexSelling =
              params.data.searchPrice.pricing.totalPrice.EUR == ""
                ? ""
                : params.data.searchPrice.pricing.totalPrice.EUR.toLocaleString(
                    "tr-TR",
                    {
                      style: "currency",
                      currency: "EUR",
                    }
                  );
            return forexSelling;
          },
          type: "rightAligned",
          headerName: "Müşt. Topl. EURO Tutarı",
          width: 140,
        },

        {
          field: "currencyPrices.exchangeRate",
          headerName: "İşlem Kuru",
          type: "rightAligned",
          width: 120,
          hide: true,
        },
        {
          field: "exchangeRatesEuro",
          headerName: "EURO-Kuru",
          type: "rightAligned",
          cellRenderer: (params) => {
            return (
              new Intl.NumberFormat("tr-TR", {
                style: "currency",
                currency: "TRY",
                minimumFractionDigits: 2,
              })
                .format(params.value)
                .replace("₺", "") + " TL"
            );
          },
          width: 120,
        },

        {
          field: "currencyPrices.vendorDaily",
          cellRenderer: (params) => {
            return `${params.value} ${params.data.currencyPrices.currencyCode}`;
          },
          type: "rightAligned",
          headerName: "API Günlük Döviz Tutarı",
          width: 175,
          hide: true,
        },
        {
          field: "searchPrice.pricing.vendorDailyPrice.EUR",
          cellStyle: (params) => {
            return params.value ===
              params?.data?.searchPrice?.pricing?.dailyPrice?.EUR
              ? { fontWeight: "light" }
              : { fontWeight: "bold" };
          },
          cellRenderer: (params) => {
            let forexSelling =
              params.data.searchPrice.pricing.vendorDailyPrice.EUR == ""
                ? ""
                : params.data.searchPrice.pricing.vendorDailyPrice.EUR.toLocaleString(
                    "tr-TR",
                    {
                      style: "currency",
                      currency: "EUR",
                    }
                  );
            return forexSelling;
          },
          type: "rightAligned",
          headerName: "API. Günl. EURO Tutarı",
          width: 140,
        },

        {
          field: "currencyPrices.vendorTotal",
          type: "rightAligned",
          width: 250,
          cellRenderer: (params) => {
            return `${params.value} ${params.data.currencyPrices.currencyCode}`;
          },
          headerName: "API Toplam Döviz Tutar",
          aggFunc: "sum",
          hide: true,
        },
        {
          field: "searchPrice.pricing.vendorTotalPrice.EUR",
          cellStyle: (params) => {
            return params.value ===
              params?.data?.searchPrice?.pricing?.totalPrice?.EUR
              ? { fontWeight: "light" }
              : { fontWeight: "bold" };
          },
          cellRenderer: (params) => {
            let forexSelling =
              params.data.searchPrice.pricing.vendorTotalPrice.EUR == ""
                ? ""
                : params.data.searchPrice.pricing.vendorTotalPrice.EUR.toLocaleString(
                    "tr-TR",
                    {
                      style: "currency",
                      currency: "EUR",
                    }
                  );
            return forexSelling;
          },
          type: "rightAligned",
          headerName: "API. Topl. EURO Tutarı",
          width: 140,
        },

        {
          field: "invoiceDate",
          headerName: "Fatura Tarihi",
          width: 130,
          cellRenderer: (params) => {
            return params.value
              ? moment(params.value).format("DD.MM.YYYY")
              : "";
          },
        },
        {
          field: "ERP.invoiceNumber",
          headerName: "Fatura No",
          width: 130,
        },
        {
          field: "consensusRejectReason",
          cellRenderer: (params) => {
            return params.value == "" ? "-" : params.value;
          },
          headerName: "Ret Sebebi",
          width: 130,
        },

        {
          field: "dateDelivery",
          cellRenderer: (params) => {
            return moment(params.value).format("DD.MM.YYYY");
          },
          headerName: "Kiralama Başlangıç",
          type: "rightAligned",
          width: 100,
        },
        {
          field: "carDeliveryReturn.delivery.name",
          headerName: "Alış Ofis",
          width: 200,
        },
        {
          field: "dateReturn",
          cellRenderer: (params) => {
            return moment(params.value).format("DD.MM.YYYY");
          },
          headerName: "Kiralama Bitiş",
          type: "rightAligned",
          width: 100,
        },

        {
          field: "carDeliveryReturn.return.name",
          headerName: "İade Ofis",
          width: 200,
        },
        {
          field: "sourceUtm",
          cellRenderer: (params) => {
            return params.value == "" ? "-" : params.value;
          },
          headerName: "Kaynak",
          width: 100,
        },
        {
          field: "statusEarlyReturn",
          valueGetter: (params) => {
            return (
              this.$store.state.ceviri[
                "earlyReturn_" + params?.data?.statusEarlyReturn
              ] || "-"
            );
          },
          headerName: "Erken İade",
          width: 120,
        },
      ],
      defaultColDef: {
        sortable: true,
        resizable: true,
        filter: true,
      },
      rowData: [],

      sideBar: {
        // Grid yan çubuk tanımla ması
        toolPanels: [
          {
            id: "columns",
            labelDefault: "Sütunlar",
            labelKey: "columns",
            iconKey: "columns",
            toolPanel: "agColumnsToolPanel",
          },
          {
            id: "filters",
            labelDefault: "Filtreler",
            labelKey: "filters",
            iconKey: "filter",
            toolPanel: "agFiltersToolPanel",
          },
        ],
        defaultToolPanel: "",
      },
      rowSelection: "multiple", // Satır Seçim Türü
      rowClassRules: null,

      statusBar: {
        // Durum çubuğunda kullanılacak durum çubuğu bileşenlerini belirtir.
        statusPanels: [
          {
            statusPanel: "agTotalAndFilteredRowCountComponent",
            align: "left",
          },
          {
            statusPanel: "agTotalRowCountComponent",
            align: "center",
          },
          {
            statusPanel: "agFilteredRowCountComponent",
          },
          {
            statusPanel: "agSelectedRowCountComponent",
          },
          {
            statusPanel: "agAggregationComponent",
          },
        ],
      },
      buttonUpdate: "",
      // SEARCH FORM
      searchFormCollapsed: false,
      searchStatusOptions: [
        {
          value: "pending",
          label: "BEKLİYOR",
        },
        {
          value: "reject",
          label: "RET",
        },
        {
          value: "completed",
          label: "MUTABIK",
        },
      ],
      statusOptions: [
        {
          value: "pending",
          label: "BEKLİYOR",
        },
        {
          value: "reject",
          label: "RET",
        },
        {
          value: "completed",
          label: "MUTABIK",
        },
        {
          value: "completed",
          label: "TÜMÜ",
        },
      ],
      year: moment().format("YYYY"),
      mount: moment().format("MMMM"),
      buttonShow: false,
      buttonText: "",
      startdDate: "",
      endDate: "",
      status: "all",
      searchForm: {
        vendor: "",
        startDate: "",
        endDate: "",
        date: [
          // ? first day of last month
          moment().subtract(1, "months").startOf("month").format("YYYY-MM-DD"),
          // ? last day of last month
          moment().subtract(1, "months").endOf("month").format("YYYY-MM-DD"),
        ],
        rezervationCode: "",
      },
      items: {
        vendors: [],
        mounts: [
          "Ocak",
          "Şubat",
          "Mart",
          "Nisan",
          "Mayıs",
          "Haziran",
          "Temmuz",
          "Ağustos",
          "Eylül",
          "Ekim",
          "Kasım",
          "Aralık",
        ],
        status: [
          {
            value: "pending",
            label: "Bekliyor",
          },
          {
            value: "completed",
            label: "Tamamlandı",
          },
        ],
      },
      modalUpdate: false,
      modalUpdateForm: {},
    };
  },
  computed: {
    years: function () {
      let start = 2021;
      let end = Number(moment().add(1, "y").format("YYYY"));
      let list = [];

      for (var i = start; i <= end; i++) {
        list.push(i.toString());
      }

      return list;
    },
    subTitle: function () {
      var result;
      if (this.status == "") {
        result = "Hepsi";
      } else if (this.status == "completed") {
        result = "Tamamlananlar";
      } else if (this.status == "pending") {
        result = "Beklemede";
      } else if (this.status == "approved") {
        result = "Onaylananlar";
      } else if (this.status == "rejects") {
        result = "Reddedilenler";
      }
      return result;
    },
    subColor: function () {
      var result;
      if (this.status == "") {
        result = "primary";
      } else if (this.status == "completed") {
        result = "info";
      } else if (this.status == "pending") {
        result = "warning";
      } else if (this.status == "approved") {
        result = "success";
      } else if (this.status == "rejects") {
        result = "danger";
      }
      return result;
    },
    vendorID: function () {
      let vendor = _.find(this.items.vendors, {
        brandSlug: this.searchForm.vendor,
      });
      return vendor ? vendor._id : null;
    },
  },
  watch: {
    "searchForm.vendor": function (newVal) {
      this.search();
    },

    status: function (newVal) {
      this.search();
    },
  },
  async created() {
    await this.search();
    this.itemsVendor();
    if (!_.isEmpty(this.$route.params)) {
      this.status = this.$route.params.consensusStatus;
    } else {
      this.status = "";
    }
    this.buttonStatus();

    this.rowClassRules = {
      // apply green to 2008
      "rag-green-outer": function (params) {
        return params?.data?.status === "MUTABIK";
      },
      "rag-red-outer": function (params) {
        return params?.data?.status === "RET";
      },
    };
    //if (localStorage.getItem("gridMutabakat"))
     // localStorage.removeItem("gridMutabakat");
  },
  methods: {
    onSelectionChanged() {
      var selectedRows = this.gridApi.getSelectedRows();
      var selectedTotalRentalPrice = 0;
      var selectedComissionAmount = 0;
      var selectedDiscountVendorAllTotalPrice = 0;
      var selectedRentalDay = 0;
      var selectedAverageDay = 0;
      var selectedAmountPaid = 0;
      const localPriceFormatted = (val) =>
        new Intl.NumberFormat("tr-TR", {
          style: "currency",
          currency: "TRY",
          minimumFractionDigits: 2,
        })
          .format(val)
          .replace("₺", "") + " TL";
      if (selectedRows.length > 0) {
        selectedRows.forEach(function (selectedRow, index) {
          selectedTotalRentalPrice += selectedRow.totalRentalPrice;
          selectedComissionAmount += selectedRow.commissionAmount;
          selectedDiscountVendorAllTotalPrice +=
            selectedRow.discountedVendorTotalPrice;
          selectedRentalDay += selectedRow.rentalDays;
          selectedAverageDay = (
            selectedRentalDay / selectedRows.length
          ).toFixed(0);
          selectedAmountPaid += selectedRow.amountPaid;
        });
      } else {
        selectedTotalRentalPrice = this.rowData.reduce(
          (pre, cur) => pre + cur.totalPrice,
          0
        );
        selectedComissionAmount = this.rowData.reduce(
          (pre, cur) => pre + cur.commissionAmount,
          0
        );
        selectedDiscountVendorAllTotalPrice = this.rowData.reduce(
          (pre, cur) => pre + cur.discountedVendorTotalPrice,
          0
        );
        selectedRentalDay = this.rowData.reduce(
          (pre, cur) => pre + cur.rentalDays,
          0
        );
        selectedAmountPaid = this.rowData.reduce(
          (pre, cur) => pre + cur.amountPaid,
          0
        );
        selectedAverageDay = (selectedRentalDay / this.rowData.length).toFixed(
          0
        );
      }
      this.statusBarTotalPrice = localPriceFormatted(selectedTotalRentalPrice);
      this.statusBarTotalCommisionPrice = localPriceFormatted(
        selectedComissionAmount
      );
      this.discountVendorAllTotalPrice = localPriceFormatted(
        selectedDiscountVendorAllTotalPrice
      );
      this.statusBarTotalDay = selectedRentalDay;
      this.vendorTotalAmount = localPriceFormatted(selectedAmountPaid);

      this.averageDay = selectedAverageDay;
    },
    async reasonForRejectAction() {
      try {
        await this.update(
          this.reasonForReject.row,
          "reject",
          this.reasonForReject.data
        );
      } catch (error) {}
    },
    getClasses() {
      let classes = {
        "ag-theme-balham": true,
        "fp-table-open": true,
      };

      return classes;
    },
    onFilterTextBoxChanged() {
      this.gridApi.setQuickFilter(this.searchFilterInput);
    },

    getGridContextMenuItems(params) {
      const _this = this;
      const selectedRows = params.api.getSelectedRows();
      //_this.reasonForReject.row = selectedRows;
      var result = [
        {
          name: "MUTABIK işaretle (" + selectedRows.length + " Adet)",
          disabled: selectedRows.length === 0 ? true : false,
          action: async function () {
            await _this.update(selectedRows, "completed");
          },
          cssClasses: ["redFont", "bold"],
        },
        /**
        {
          name: "RET işaretle (" + selectedRows.length + " Adet)",
          disabled: selectedRows.length === 0 ? true : false,
          action: async function () {
            _this.reasonForReject.modalShow = true;
            //            await _this.update(selectedRows, "reject");
          },
          cssClasses: ["redFont", "bold"],

        },
       *  */
        {
          name: "BEKLİYOR işaretle (" + selectedRows.length + " Adet)",
          disabled: selectedRows.length === 0 ? true : false,
          action: async function () {
            await _this.update(selectedRows, "pending");
          },
          cssClasses: ["redFont", "bold"],
        },
        "separator",
        "separator",
        "chartRange",
        "copy",
        "export",
      ];
      return result;
    },
    buttonStatus() {
      if (this.status == "pending") {
        this.buttonShow = true;
        this.buttonText = "Mutabakatı Tamala";
        this.buttonColor = "info";
        this.buttonUpdate = "completed";
      } else if (this.status == "rejects") {
        this.buttonShow = true;
        this.buttonText = "Mutabakatı Tekrar Başlat";
        this.buttonColor = "warning";
        this.buttonUpdate = "pending";
      } else {
        this.buttonShow = false;
        this.buttonText = "";
        this.buttonColor = "";
      }
    },

    onGridReady(params) {
      this.gridApi = params.api;
      this.gridColumnApi = params.columnApi;
      var columnState = JSON.parse(localStorage.getItem("gridMutabakat"));
      if (columnState) {
        params.columnApi.applyColumnState({
          state: columnState,
          applyOrder: true,
        });
      }
    },
    onColumnMoved(params) {
      var columnState = JSON.stringify(params.columnApi.getColumnState());
      localStorage.setItem("gridMutabakat", columnState);
    },
    async itemsVendor() {
      try {
        let vendors = await axios.get(
          process.env.VUE_APP_API_URL + "admin/vendor?sort=1",
          {
            withCredentials: true,
            params: { status: true },
          }
        );
        const reservation = this.rowData.filter((item) => item.vendor);

        this.items.vendors = vendors.data.vendorList.filter((item) => {
          return reservation.some((data) => item.brandSlug === data.vendor);
        });
      } catch (error) {}
    },
    onRowDoubleClicked(params) {
      let routeData = this.$router.resolve({
        name: "Rezervasyon Detayı",
        params: {
          reservationCode: params.node.data.reservationCode,
        },
      });
      window.open(routeData.href, "_blank");
    },
    async search(reservationCode) {
      this.loadingGrid = true;
      let body = "";

      if (this.status != "all") {
        body += "&consensusStatus=" + this.status;
      }

      if (reservationCode) {
        body += "&reservationCode=" + reservationCode;
      }

      if (this.searchForm.vendor) {
        var vendor = _.find(this.items.vendors, {
          brandSlug: this.searchForm.vendor,
        });
        body += "&vendor=" + this.searchForm.vendor;
        body += "&plusTotalPrice=" + vendor.plusTotalPrice;
      }

      if (this.searchForm.date.length > 0) {
        body += "&startDate=" + this.searchForm.date[0] + " 00:00:00";
        body += "&endDate=" + this.searchForm.date[1] + " 23:59:59";
      }

      const rates = await axios.get(
        process.env.VUE_APP_API_URL + "admin/consensus?search=1" + body,
        { withCredentials: true }
      );
      {
        const localPriceFormatted = (val) =>
          new Intl.NumberFormat("tr-TR", {
            style: "currency",
            currency: "TRY",
            minimumFractionDigits: 2,
          })
            .format(val)
            .replace("₺", "") + " TL";
        this.discountVendorAllTotalPrice = localPriceFormatted(
          rates.data.reduce(
            (pre, cur) => pre + cur.discountedVendorTotalPrice,
            0
          )
        );
        this.statusBarTotalPrice = localPriceFormatted(
          rates.data.reduce((pre, cur) => pre + cur.totalPrice, 0)
        );
        this.statusBarTotalCommisionPrice = localPriceFormatted(
          rates.data.reduce((pre, cur) => pre + cur.commissionAmount, 0)
        );
        this.statusBarTotalDay = rates.data.reduce(
          (pre, cur) => pre + cur.rentalDays,
          0
        );
        this.vendorTotalAmount = localPriceFormatted(
          rates.data.reduce((pre, cur) => pre + cur.amountPaid, 0)
        );
        const resLength = rates.data.length;
        this.averageDay = (this.statusBarTotalDay / resLength).toFixed(0);
      }
      this.rowData = rates.data;
      //this.gridShow = true;
      this.loadingGrid = false;
      this.itemsVendor();
    },
    // updatea(consensusStatusTo) {
    //   let text = "";
    //   if (consensusStatusTo == "completed") {
    //     text =
    //       this.searchForm.vendor +
    //       " firmasının Mutabakatını tamamlamak istiyor musunuz?";
    //   } else if (consensusStatusTo == "pending") {
    //     text =
    //       this.searchForm.vendor +
    //       " firmasının Mutabakatını tekrardan işleme almak istiyor musunuz?";
    //   }

    //   Vue.swal
    //     .fire({
    //       title: "Uyarı ?",
    //       text,
    //       icon: "question",
    //       showCancelButton: true,
    //       showCloseButton: true,
    //       confirmButtonText: "Evet",
    //       cancelButtonText: "Hayır",
    //     })
    //     .then((result) => {
    //       if (result.isConfirmed) {
    //         this.$Progress.start();
    //         try {
    //           axios.put(
    //             process.env.VUE_APP_API_URL + "admin/consensus",
    //             {
    //               status: "completed",
    //               vendor: this.searchForm.vendor,
    //               consensusStatusFrom: this.status,
    //               consensusStatusTo,
    //               startDate: this.startDate,
    //               endDate: this.endDate,
    //             },
    //             { withCredentials: true }
    //           );

    //           this.search();
    //           this.$store.dispatch({
    //             type: "successSwal",
    //             text: "Mutabakat Durumu başarılı bir şekilde güncellendi",
    //             refresh: true,
    //           });
    //         } catch (error) {
    //           Vue.swal.fire({
    //             icon: "error",
    //             showCloseButton: true,
    //             text: error.response.data.message,
    //           });
    //         }
    //       }
    //     });
    // },

    async update(data, status, params) {
      this.loadingGrid = true;

      try {
        console.log("data", data);
        console.log("status", status);
        console.log("params", params);
        let promises = data.map(
          async (item) => 
            await axios.put(
              process.env.VUE_APP_API_URL + "admin/consensus",
              {
                _id: item._id,
                status,
                rejectReason: params,
              },
              {
                withCredentials: true,
              }
            )
        );
        promises = await Promise.all(promises);
        this.$store.dispatch({
          type: "successSwal",
          text: "Başarılı Bir Şekilde Güncellendi",
        });
        await this.search();
      } catch (error) {
        Vue.swal.fire({
          icon: "error",
          showCloseButton: true,
          text: error.response.data.message,
        });
      } finally {
        if (this.reasonForReject) this.reasonForReject = false;
        this.loadingGrid = false;
      }
    },
  },
};
</script>
<style>
.vs__selected-options {
  height: 20px;
  margin: 0;
  padding: 0;
  width: 100%;
}

.vs__search {
  margin: 0 !important;
  color: #5c6873 !important;
}

.rag-green-outer {
  background-color: rgb(121, 175, 121) !important;
}

.rag-red-outer {
  background-color: rgb(175, 121, 121) !important;
}

.custom-status-bar {
  width: 100%;
  height: 50px;
  position: absolute;
  bottom: 0;
  border: 1px solid #bdc3c7;
  background-color: #f5f7f7;
  display: flex;
  align-items: center;
  justify-content: center;
}

.custom-status-bar span {
  margin-right: 1.5rem;
}
</style>
